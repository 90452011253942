import React from 'react'

const Syllabics = ({ children, className = "" }) => {
  return (
    <span className={`font-syllabics ${className}`}>
      {children}
    </span>
  )
}

export default Syllabics
