import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const BackgroundImage2 = ({
  className = "",
  children,
  heroImage,
  zIndex = null,
}) => {
  const data = useStaticQuery(graphql`
    query {
      HeroImageQuery: file(
        relativePath: { eq: "tobias-nt3QMC9bfiE-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const bgStyle = {
    zIndex: zIndex,
  }
  // Set ImageData.
  const defaultHeroImage = data.HeroImageQuery.childImageSharp
  // if heroImage isn't passed, use HeroImageQuery
  const { fluid } = heroImage ? heroImage : defaultHeroImage
  return (
    <BackgroundImage
      Tag="section"
      className={`w-full h-full bg-center ${className}`}
      fluid={fluid}
      backgroundColor={`#ededed`}
      style={bgStyle}
    >
      <div className="flex h-full justify-center items-center ">{children}</div>
    </BackgroundImage>
  )
}

export default BackgroundImage2
